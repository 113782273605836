<template>
  <div>
    <!--Breadcrumb-->
    <section>
      <Breadcrumb
        :title="this.category_detail[0].category_name"
        titleType="H1"
        :array="[
          { islink: true, url: '/', text: 'Ana Sayfa' },
          { islink: true, url: '/blog', text: 'Blog' },
        ]"
      />
    </section>
    <!--/Breadcrumb-->

    <!--Add listing-->
    <section class="sptb">
      <div class="container">
        <div class="row">
          <!--BLOG LIST-->
          <div class="col-xl-8 col-lg-8 col-md-12" v-if="isLoad">
            <div
              class="card overflow-hidden"
              v-for="(item, i) in 5"
              :key="'blogLoad-' + i"
            >
              <div class="row g-0 blog-list">
                <div class="col-xl-5 col-lg-5 col-md-12">
                  <div class="card-img">
                    <div
                      style="width: 100%; height: 170px"
                      class="skeleton cover-image"
                    ></div>
                    <div class="card-text"></div>
                  </div>
                </div>
                <div class="col-xl-7 col-lg-7 col-md-12">
                  <div class="card-body">
                    <div class="item7-card-desc d-flex mb-1">
                      <div class="skeleton p-2 w-70"></div>
                      <div class="ms-auto skeleton p-2 w-20"></div>
                    </div>

                    <h4 class="skeleton font-weight-semibold p-3 mt-4"></h4>
                    <div>
                      <div class="skeleton p-1 w-60 mt-2"></div>
                      <div class="skeleton p-1 w-40 mt-2"></div>
                      <div class="skeleton p-1 w-10 mt-2"></div>
                    </div>
                    <div class="skeleton w-20 mt-2 p-4"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-8 col-lg-8 col-md-12" v-if="!isLoad" id="mobileEx">
            <template v-if="blogList == null || blogList.length == 0">
              <div class="text-center"><h3>Sonuç Bulunamadı</h3></div>
            </template>
            <div
              class="card overflow-hidden"
              v-for="(item, i) in blogList"
              :key="'blog-' + i"
            >
              <div class="row blog-list">
                <div class="col-xl-4 col-lg-4 col-md-12">
                  <div class="card-img" style="width: 115%">
                    <router-link :to="'/blog/' + item.short_name">
                      <lazy-image
                        :src="
                          'https://storage.terapivitrini.com/' + item.thumbnail
                        "
                        alt="img"
                        class="cover-image"
                    /></router-link>
                    <div class="card-text"></div>
                  </div>
                </div>
                <div class="col-xl-8 col-lg-8 col-md-12">
                  <div class="card-body">
                    <div class="item7-card-desc d-flex mb-1">
                      <router-link :to="'/blog/' + item.short_name"
                        ><i class="fa fa-calendar-o text-muted me-2"></i
                        >{{ item.created_at }}</router-link
                      >
                      <div class="ms-auto">
                        <router-link :to="'/blog/' + item.short_name"
                          ><i class="fa fa-comment-o text-muted me-2"></i
                          >{{ item.commentcount }}</router-link
                        >
                      </div>
                    </div>
                    <router-link
                      :to="'/blog/' + item.short_name"
                      class="text-dark"
                    >
                      <h4 class="font-weight-semibold">{{ item.title }}</h4>
                    </router-link>
                    <p>{{ item.description }}</p>
                    <router-link
                      :to="'/blog/' + item.short_name"
                      class="btn btn-primary btn-sm"
                      >Daha fazla</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="center-block text-center">
              <Pagination
                :currentpage="currentpage"
                :pagesize="pagesize"
                :preload="
                  '/blog/category/' +
                
                  this.$route.params.shortlink +'/page'+
                  '/'
                "
              />
            </div>
          </div>
          <!--Right Side Content-->
          <div class="col-xl-4 col-lg-4 col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control br-ts-7 br-bs-7"
                    placeholder="Blog İçinde"
                    id="search"
                  />
                  <div class="input-group-text border-0 bg-transparent p-0">
                    <button
                      type="button"
                      class="btn btn-primary br-te-7 br-be-7"
                      @click="search"
                    >
                      Ara
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <SideBar :categoryList="categoryList" />
          </div>
        </div>
      </div>
    </section>
    <!--Add listing-->
  </div>
</template>
<style scoped>
@media screen and (max-width: 990px) {
  #mobileEx {
    padding-left: 35px;
    padding-right: 35px;
  }
}
</style>
<script>
import Breadcrumb from "../components/Breadcrumb.vue";
import Pagination from "../components/Pagination.vue";
import SideBar from "./Blog/Components/SideBar.vue";
export default {
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content: this.description,
        },
      ],
    };
  },
  created() {
    this.$store.state.header = true;
    this.$store.state.footer = true;
    if (this.$route.params.page) {
      this.currentpage = this.$route.params.page;
    }
    let blogInfo = {
      begin: (this.currentpage - 1) * this.perpage,
      perpage: this.perpage,
      shortname: this.$route.params.shortlink,
    };
    this.$store.dispatch("categoryList").then((value) => {
      this.categoryList = value;
    });

    this.$store.dispatch("blogList", blogInfo).then((value) => {
      this.blogList = value.list;
      this.totalsize = value.size;
      this.pagesize = value.pages;
      this.isLoad = false;
      this.category_detail = value.list[0].categories;
      this.title = 'Blog '+this.category_detail[0].category_name + " - Terapi Vitrini";
      this.description =
        this.category_detail[0].category_name +
        " - Zihin, ruh, beden sağlığınız ve yaşadığınız çeşitli sorunlar için ipuçları ve bilgilere ulaşmak için tıklayın";
      let _this = this;
      $(document).ready(function () {
        let content = document.getElementById("appcontent").innerHTML;
        _this.$store.dispatch("setMetaContent", [
          _this.title,
          _this.description,
          "",
          "",
          "",
          content,
        ]);
      });
    });
  },
  data() {
    return {
      categoryList: [],
      blogList: [],
      category_detail: [],
      currentpage: 1,
      perpage: 50,
      totalsize: 0,
      pagesize: 0,
      isLoad: true,
      title: "",
      description: "",
    };
  },
  watch: {
    "$route.params.shortlink": function () {
      this.isLoad = true;
      let blogInfo = {
        begin: (this.currentpage - 1) * this.perpage,
        perpage: this.perpage,
        shortname: this.$route.params.shortlink,
      };
      this.$store.dispatch("blogList", blogInfo).then((value) => {
        this.blogList = value.list;
        this.totalsize = value.size;
        this.pagesize = value.pages;
        this.isLoad = false;
        this.category_detail = value.list[0].categories;
        this.title =
          this.category_detail[0].category_name + " - Terapi Vitrini";
        this.description =
          this.category_detail[0].category_name +
          " - Zihin, ruh, beden sağlığınız ve yaşadığınız çeşitli sorunlar için ipuçları ve bilgilere ulaşmak için tıklayın";
        $(document).ready(function () {
          let content = document.getElementById("appcontent").innerHTML;
          let _this = this;
          _this.$store.dispatch("setMetaContent", [
            _this.title,
            _this.description,
            "",
            "",
            "",
            content,
          ]);
        });
      });
    },
  },
  methods: {
    search() {
      let search = document.getElementById("search").value;
      this.currentpage = 1;
      this.blogList = [];
      this.blogLoad = true;
      let blogInfo = {
        begin: (this.currentpage - 1) * this.perpage,
        perpage: this.perpage,
        search: search,
        shortname: this.$route.params.shortlink,
      };
      this.$store.dispatch("blogList", blogInfo).then((value) => {
        this.blogList = value.list;
        this.totalsize = value.size;
        this.pagesize = value.pages;
        this.isLoad = false;
      });
    },
    backToTop() {
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        0
      );
      return false;
    },
  },
  components: { Breadcrumb, Pagination, SideBar },
  mounted() {
    this.backToTop();
  },
};
</script>
        