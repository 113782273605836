<template>
  <div class="card" style="min-height: 1032.4px;">
    <div class="card-header">
      <h3 class="card-title">Kategori</h3>
    </div>
    <div
      class="card-body p-0"
      :style="
        'transition: all 1s ease 0s;overflow: hidden;height:' +
        height / bol +
        'px;'
      "
    >
      <div class="list-catergory" id="box">
        <div class="item-list">
          <ul class="list-group mb-0">
            <li
              class="list-group-item"
              v-for="(item, i) in categoryList"
              :key="i"
              @click="backToTop"
            >
              <router-link
                :to="'/blog/category/' + item.short_name"
                class="text-dark"
              >
                {{ item.category_name }}
                <span class="badgetext badge rounded-pill bg-secondary mb-0">{{
                  item.count
                }}</span>
              </router-link>
              <template v-if="i == categoryList.length - 1">{{
                run()
              }}</template>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div @click="loadmore" class="loadmore">
      <i v-if="calenderToggleBool" class="fa fa-angle-down"></i>
      <i v-if="!calenderToggleBool" class="fa fa-angle-up"></i>
    </div>
  </div>
</template>
<style scoped>
.topmenu {
  padding: 0 !important;
  background: #eaeaea;
  margin-bottom: 10px;
  margin-left: -10px;
  margin-top: -10px;
  height: 52px;
}
.loadmore {
  text-align: center;
  font-size: 20px;
  box-shadow: 0px -16px 15px -14px #000000;
  position: inherit;
}
.loadmore:hover {
  cursor: pointer;
}
.left {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: absolute;
  left: -15px;
  top: 60px;
  z-index: 9;
  padding: 11px !important;
  background: white;
  box-shadow: -10px 0px 10px -5px #cfcfcf;
}
.right {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: absolute;
  right: -15px;
  top: 60px;
  z-index: 9;
  padding: 11px !important;
  background: white;
  box-shadow: 10px 0px 10px -5px #cfcfcf;
}
.dateList {
  display: inline-flex;
  position: absolute;
  left: 0;
  top: 0;
}
.dateList div {
  width: 88px;
  text-align: center;
  padding: 10px;
}
.dateList li {
  padding: 5px;
  margin: 5px;
  color: white;
  border-radius: 10px;
}
.dateList li a {
  color: white;
  cursor: pointer;
}
.dateList li a:hover {
  color: white !important;
  cursor: pointer;
}
</style>
<script>
export default {
  props: ["categoryList"],
  created() {},
  data() {
    return {
      height: 0,
      bol: 10,
      list: [],
      calenderToggleBool: true,
      loader: true,
      errorMode: false,
      errorMessage: "",
    };
  },
  mounted: function () {},
  methods: {
    run() {
      setTimeout(() => {
        this.height = document.getElementById("box").offsetHeight + 10;
      }, 500);
    },
    backToTop() {
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        0
      );
      return false;
    },
    loadmore() {
      if (this.bol == 1) {
        this.calenderToggleBool = true;
        this.bol = 10;
      } else {
        this.calenderToggleBool = false;
        this.bol = 1;
      }
    },
  },
};
</script>