var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',[_c('Breadcrumb',{attrs:{"title":this.category_detail[0].category_name,"titleType":"H1","array":[
        { islink: true, url: '/', text: 'Ana Sayfa' },
        { islink: true, url: '/blog', text: 'Blog' },
      ]}})],1),_c('section',{staticClass:"sptb"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[(_vm.isLoad)?_c('div',{staticClass:"col-xl-8 col-lg-8 col-md-12"},_vm._l((5),function(item,i){return _c('div',{key:'blogLoad-' + i,staticClass:"card overflow-hidden"},[_vm._m(0,true)])}),0):_vm._e(),(!_vm.isLoad)?_c('div',{staticClass:"col-xl-8 col-lg-8 col-md-12",attrs:{"id":"mobileEx"}},[(_vm.blogList == null || _vm.blogList.length == 0)?[_vm._m(1)]:_vm._e(),_vm._l((_vm.blogList),function(item,i){return _c('div',{key:'blog-' + i,staticClass:"card overflow-hidden"},[_c('div',{staticClass:"row blog-list"},[_c('div',{staticClass:"col-xl-4 col-lg-4 col-md-12"},[_c('div',{staticClass:"card-img",staticStyle:{"width":"115%"}},[_c('router-link',{attrs:{"to":'/blog/' + item.short_name}},[_c('lazy-image',{staticClass:"cover-image",attrs:{"src":'https://storage.terapivitrini.com/' + item.thumbnail,"alt":"img"}})],1),_c('div',{staticClass:"card-text"})],1)]),_c('div',{staticClass:"col-xl-8 col-lg-8 col-md-12"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"item7-card-desc d-flex mb-1"},[_c('router-link',{attrs:{"to":'/blog/' + item.short_name}},[_c('i',{staticClass:"fa fa-calendar-o text-muted me-2"}),_vm._v(_vm._s(item.created_at))]),_c('div',{staticClass:"ms-auto"},[_c('router-link',{attrs:{"to":'/blog/' + item.short_name}},[_c('i',{staticClass:"fa fa-comment-o text-muted me-2"}),_vm._v(_vm._s(item.commentcount))])],1)],1),_c('router-link',{staticClass:"text-dark",attrs:{"to":'/blog/' + item.short_name}},[_c('h4',{staticClass:"font-weight-semibold"},[_vm._v(_vm._s(item.title))])]),_c('p',[_vm._v(_vm._s(item.description))]),_c('router-link',{staticClass:"btn btn-primary btn-sm",attrs:{"to":'/blog/' + item.short_name}},[_vm._v("Daha fazla")])],1)])])])}),_c('div',{staticClass:"center-block text-center"},[_c('Pagination',{attrs:{"currentpage":_vm.currentpage,"pagesize":_vm.pagesize,"preload":'/blog/category/' +
              
                this.$route.params.shortlink +'/page'+
                '/'}})],1)],2):_vm._e(),_c('div',{staticClass:"col-xl-4 col-lg-4 col-md-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"input-group"},[_c('input',{staticClass:"form-control br-ts-7 br-bs-7",attrs:{"type":"text","placeholder":"Blog İçinde","id":"search"}}),_c('div',{staticClass:"input-group-text border-0 bg-transparent p-0"},[_c('button',{staticClass:"btn btn-primary br-te-7 br-be-7",attrs:{"type":"button"},on:{"click":_vm.search}},[_vm._v(" Ara ")])])])])]),_c('SideBar',{attrs:{"categoryList":_vm.categoryList}})],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row g-0 blog-list"},[_c('div',{staticClass:"col-xl-5 col-lg-5 col-md-12"},[_c('div',{staticClass:"card-img"},[_c('div',{staticClass:"skeleton cover-image",staticStyle:{"width":"100%","height":"170px"}}),_c('div',{staticClass:"card-text"})])]),_c('div',{staticClass:"col-xl-7 col-lg-7 col-md-12"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"item7-card-desc d-flex mb-1"},[_c('div',{staticClass:"skeleton p-2 w-70"}),_c('div',{staticClass:"ms-auto skeleton p-2 w-20"})]),_c('h4',{staticClass:"skeleton font-weight-semibold p-3 mt-4"}),_c('div',[_c('div',{staticClass:"skeleton p-1 w-60 mt-2"}),_c('div',{staticClass:"skeleton p-1 w-40 mt-2"}),_c('div',{staticClass:"skeleton p-1 w-10 mt-2"})]),_c('div',{staticClass:"skeleton w-20 mt-2 p-4"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('h3',[_vm._v("Sonuç Bulunamadı")])])
}]

export { render, staticRenderFns }